@charset "UTF-8";

@import "../_setting";
@import "../_mixin";

.top-content-heading {
  position: relative;
  padding-top: 150px;
  font-family: $Play;
  @include f-size(40);
  @include tracking(25);
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
  @include bpSp {
    @include f-size(35);
    padding-top: 60px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1px;
    height: 125px;
    background-color: $color6;
    @include bpSp {
      height: 60px;
    }
  }
  &-title {}
}


.l-main {
}

.mv-wrap {
  text-align: center;
}

.top-concept {
  position: relative;
  padding-top: 95px;
  padding-bottom: 95px;
  background: linear-gradient(180deg, $color2 0%, $color2 40%, $color3 40%, $color3 100%);
  @include bpSp {
    padding-top: 50px;
    padding-bottom: 50px;
    background: linear-gradient(180deg, $color2 0%, $color2 45%, $color3 45%, $color3 100%);
  }
  &-obj {
    position: absolute;
    top: 0;
    left: 0;
    width: 65%;
    z-index: 0;
    pointer-events: none;
  }

  &-content {
    position: relative;
    z-index: 1;
  }

  &-image {
    margin-top: 60px;
    text-align: center;
    @include bpSp {
      margin-top: 35px;
    }
  }

  &-text {
    &_ja {
      margin-top: 30px;
      @include f-size(20);
      @include tracking(60);
      text-align: center;
      line-height: 2.1;
      @include bpSp {
        margin-top: 20px;
        @include f-size(16);
      }
    }
    &_en {
      margin-top: 60px;
      font-family: $Muli;
      @include f-size(17);
      @include tracking(50);
      text-align: center;
      line-height: 1.9;
      @include bpSp {
        @include f-size(15);
        margin-top: 35px;
      }
    }
  }

  &-view {
    margin-top: 45px;
  }
}

.top-works {
  position: relative;
  padding-top: 95px;
  padding-bottom: 165px;
  @include bpSp {
    padding-top: 45px;
    padding-bottom: 60px;
  }
  &-heading {
    margin-top: 30px;
    @include bpSp {
      margin-top: 20px;
    }
  }

  &-objWrap {
    @include bpSp {
      display: flex;
      justify-content: space-between;
      padding-right: $baseWidthPadding;
      padding-left: $baseWidthPadding;
    }
  }
  &-obj01 {
    position: absolute;
    top: -115px;
    right: 50%;
    transform: translateX(100%);
    margin-right: -355px;
    @include bpSp {
      position: static;
      display: inline-block;
      width: 49%;
      transform: translateX(0%);
      margin-right: 0px;
    }
  }
  &-obj02 {
    position: absolute;
    top: 205px;
    left: 50%;
    transform: translateX(-100%);
    margin-left: -355px;
    @include bpSp {
      position: static;
      display: inline-block;
      width: 49%;
      transform: translateX(0%);
      margin-left: 0px;
    }
  }

  &-content {
    &-title {
      margin-top: 35px;
      @include f-size(22);
      @include tracking(60);
      text-align: center;
      @include bpSp {
        @include f-size(18);
        margin-top: 20px;
      }
    }
    &-text {
      margin-top: 30px;
      @include f-size(14);
      @include tracking(60);
      line-height: 2.1;
      text-align: center;
      @include bpSp {
        margin-top: 20px;
        text-align: left;
      }
    }
  }

  &-btn {
    margin-top: 35px;
  }
}

.top-brand {
  position: relative;
  padding-top: 115px;
  padding-bottom: 95px;
  background-color: $color4;
  @include bpSp {
    padding-bottom: 55px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: -8vw;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../../img/top/brand-obj.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 30%;
    pointer-events: none;
    @include bpTb {
      display: none;
    }
  }
  &-heading {
    margin-top: -165px;
    @include bpSp {}
  }

  &-menedeux {
    margin-top: 60px;
    text-align: center;
    @include bpSp {
      margin-top: 45px;
    }
  }

  &-content {
    margin-top: 30px;
    &-title {
      text-align: center;
      @include f-size(22);
      @include tracking(60);
      line-height: 1.9;
      @include bpSp {
        @include f-size(18);
      }
    }
    &-text {
      margin-top: 25px;
      text-align: center;
      @include tracking(60);
      line-height: 2.1;
      @include bpSp {
        text-align: left;
      }
    }
  }

  &-btn {
    margin-top: 20px;
  }

  &-box {
    margin-top: 60px;
    &_main {
      position: relative;
      &-image {
        pointer-events: none;
      }
      &-text {
        position: absolute;
        top: 75px;
        left: 50px;
        color: #ffffff;
        font-family: $Muli;
        @include f-size(17);
        @include tracking(50);
        line-height: 1.9;
        font-weight: 300;
        @include bpSp {
          left: 0;
          width: 100%;
          padding-right: $baseWidthPadding;
          padding-left: $baseWidthPadding;
          @include f-size(13);
          text-align: center;
        }
      }
    }

    &_sub {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

}

.top-online {
  background-color: $color3;
  &-heading {}
  &-title {
    position: relative;
    display: block;
    padding-top: 45px;
    padding-bottom: 45px;
    font-family: $Play;
    @include f-size(32);
    text-align: center;
    text-transform: uppercase;
    @include bpSp {
      display: flex;
      justify-content: flex-start;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 20px;
      @include f-size(24);
      line-height: 1.3;
      text-align: left;
    }
    img {
      vertical-align: middle;
      @include bpSp {
        margin-right: 10px;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.top-calligraphy {
  padding-top: 175px;
  padding-bottom: 80px;
  background-image: url(../../img/top/calligraphy-obj.png);
  background-repeat: no-repeat;
  background-position: left 7%;
  &-wrap {
    display: flex;
    justify-content: center;
    @include bpSp {
      display: block;
    }
  }
  &-content {
    max-width: 935px;
    width: 100%;
    @include bpSp {
      padding-right: $baseWidthPadding;
      padding-left: $baseWidthPadding;
    }
  }
  &-heading {
    margin-top: -20px;
  }
  &-title {
    margin-top: 40px;
    @include f-size(22);
    @include tracking(60);
    line-height: 1.9;
    text-align: center;
    @include bpSp {
      @include f-size(19);
    }
  }
  &-text {
    margin-top: 20px;
    font-family: $Muli;
    @include f-size(17);
    @include tracking(50);
    text-align: center;
    @include bpSp {
      @include f-size(15);
    }
  }
  &-btn {
    margin-top: 25px;
  }
  &-imageWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    @include bpSp {
      margin-top: 45px;
    }
  }

  &-image {
    margin-left: 40px;
    @include bpSp {
      margin-top: 30px;
      margin-left: 0;
      padding-right: $baseWidthPadding;
      padding-left: $baseWidthPadding;
    }
  }
}

.top-news {
  padding-top: 80px;
  padding-bottom: 85px;
  background-color: $color9;
  @include bpSp {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include bpSp {
      display: block;
    }
  }

  &-heading {
    max-width: 300px;
    width: 100%;
    @include bpSp {
      max-width: 100%;
      text-align: center;
    }
  }
  &-post {
    max-width: 685px;
    width: 100%;
    margin-left: 30px;
    @include bpSp {
      max-width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }
  }

  &-btn {
    @include bpSp {
      display: none;
    }
  }
  &-btn_post {
    display: none;
    @include bpSp {
      display: block;
      margin-top: 30px;
    }
  }

}

.top-blog {
  padding-top: 75px;
  padding-bottom: 80px;
  background-color: $color3;

  &-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    @include bpSp {
      flex-direction: column;
    }
  }
  &-heading {
    padding-right: 40px;
    @include bpSp {
      padding-right: 0;
    }
  }

  &-post {
    max-width: 670px;
    width: 100%;
    @include bpSp {
      margin-top: 30px;
    }

    .blog-list-item {
      margin-bottom: 0;
    }
  }

  &-btn {
    @include bpSp {
      display: none;
    }
  }

  &-btn_post {
    display: none;
    @include bpSp {
      display: block;
      margin-top: 30px;
    }
  }
}

.top-youtube {
  padding-top: 90px;
  padding-bottom: 90px;
  @include bpSp {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-box {
    margin-top: 40px;
    @include bpSp {
      margin-top: 25px;
    }
  }

  &-list {
    display: flex;
    justify-content: center;
    @include bpSp {
      display: block;
    }
    &-item {
      @include bpSp {
        width: 80%;
        margin-right: auto;
        margin-left: auto;
      }
      & + & {
        margin-left: 30px;
        @include bpSp {
          margin-top: 20px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    &-link {}
    &-image {}
    &-title {
      margin-top: 25px;
      @include f-size(18);
      @include tracking(60);
      font-weight: 600;
      text-align: center;
      @include bpSp {
        margin-top: 15px;
      }
    }
  }
}

.top-contact {
  padding-top: 105px;
  padding-bottom: 100px;
  background-color: $color4;
  @include bpSp {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &-wrap {}

  &-content {
    margin-top: 35px;
    @include bpSp {
      margin-top: 20px;
    }
    &-title {
      @include f-size(22);
      @include tracking(60);
      line-height: 1.9;
      text-align: center;
      @include bpSp {
        @include f-size(18);
      }
    }
  }
  &-btn {
    margin-top: 30px;
  }
}